import React, { useState } from 'react';
import SpinnerComparsas from '../spinners/SpinnerComparsas';
import Grid from '../GridPrepare'; // Asegúrate de ajustar la ruta si es necesario
import Relleno from '../menu/CompMenuMBT';
import Minibanner from '../menu/CompMenuMinibanner';

function ListaOrdenes() {
    // Estado para la selección temporal
    const [selectedIdTemporal, setSelectedIdTemporal] = useState(localStorage.getItem('selectedIdTemporal') || '0');

    const handleSelectionChange = (id) => {
        // Actualiza solo el estado temporal
        // Se modifica el estado temporal LocalStorage
        localStorage.setItem('selectedIdTemporal', id);
        setSelectedIdTemporal(id);
    };
    
    return (
        <div className="home-act-ord">
            <Minibanner />
            <Relleno />
            <div className="contenedor-spinner">
                {<SpinnerComparsas onSelectionChange={handleSelectionChange} selectedId={selectedIdTemporal} type="ordenes" />}
            </div>
            <Grid type="ordenes" id={selectedIdTemporal} />
        </div>
    );
}

export default ListaOrdenes;
