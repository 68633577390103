import React from 'react';
import GridAVISOSperfil from './GridAVISOSperfil'; // Importar el componente

function GridAVISOS({ avisos }) {
  return (
    <div className="home2">
      <div className="home-vent vent">
        <div className="title-vent">
          <b>AVISOS IMPORTANTES</b>
          <br />
        </div>
        <br />
        <GridAVISOSperfil avisos={avisos} />
      </div>
    </div>
  );
}

export default GridAVISOS;
