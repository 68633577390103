import React from 'react';
import ListaActos from '../components/vistas/ListaActos.js';

function PageListaActos() {
  return (
    <div>
      <ListaActos />
    </div>
  );
}

export default PageListaActos;
