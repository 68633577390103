// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import menuBanner from '../../assets/menus/menu_1000_b.jpg'; // Imagen alternativa

// function CompMenuBanner() {
//   const [banner, setBanner] = useState(null);
//   const [isOffline, setIsOffline] = useState(false);

//   useEffect(() => {
//     const fetchBanners = async () => {
//       try {
//         console.log('Realizando la consulta a la API para obtener banners'); // Este log te indicará cuántas veces se hace la consulta
//         const response = await fetch(`${process.env.REACT_APP_API_URL}/banner`);
//         if (!response.ok) {
//           throw new Error('Error al obtener los banners');
//         }
//         const banners = await response.json();

//         // Obtener el índice del último banner mostrado desde localStorage
//         let currentIndex = parseInt(localStorage.getItem('bannerIndex'), 10) || 0;

//         // Incrementar el índice para mostrar el siguiente banner
//         currentIndex = (currentIndex + 1) % banners.length;

//         // Guardar el nuevo índice en localStorage
//         localStorage.setItem('bannerIndex', currentIndex);

//         // Establecer el banner actual
//         let currentBanner = banners[currentIndex];

//         // Verificar si el enlace ya existe y es válido
//         const enlaceExiste = currentBanner.enlace_banner && currentBanner.enlace_banner.trim() !== "";

//         // Si el enlace está vacío o nulo, asignar una ruta alternativa
//         if (!enlaceExiste) {
//           currentBanner.enlace_banner = `/comercio/${currentBanner.id}`; // Ruta alternativa
//         }
//         console.log(currentBanner.id); // Este log te indicará cuántas veces se hace la consulta
//         setBanner(currentBanner);
//       } catch (error) {
//         console.error('Error al obtener el banner:', error);
//         setIsOffline(true); // Marca que no hay conexión o falló la consulta
//       }
//     };

//     fetchBanners();
//   }, []); // El array vacío hace que useEffect se ejecute solo una vez al montar el componente

//   // Si está offline o no se cargó el banner, muestra la imagen alternativa
//   if (isOffline || !banner) {
//     return (
//       <div className="banner">
//         <img src={menuBanner} alt="mycapp" />
//       </div>
//     );
//   }

//   // Si el banner se cargó correctamente
//   return (
//     <Link
//       to={banner.enlace_banner}
//       style={{ textDecoration: 'none' }}
//       className="banner"
//       target={banner.enlace_banner.startsWith('http') ? "_blank" : "_self"} // Si el enlace es externo, se abre en una pestaña nueva
//       rel={banner.enlace_banner.startsWith('http') ? "noopener noreferrer" : undefined} // Para seguridad al abrir en una nueva pestaña
//     >
//       <div>
//         <img src={`/banners/${banner.imagen}`} alt="Banner" />
//       </div>
//     </Link>
//   );
// }

// export default CompMenuBanner;







import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import menuBanner from '../../assets/menus/menu_1000_b.jpg'; // Imagen alternativa

function CompMenuBanner() {
  const [banners, setBanners] = useState([]); // Todos los banners
  const [currentIndex, setCurrentIndex] = useState(
    () => parseInt(localStorage.getItem('bannerIndex'), 10) || 0
  );
  const [isOffline, setIsOffline] = useState(false);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        console.log('Realizando la consulta a la API para obtener banners');
        const response = await fetch(`${process.env.REACT_APP_API_URL}/banner`);
        if (!response.ok) {
          throw new Error('Error al obtener los banners');
        }
        const data = await response.json();
        if (data.length > 0) {
          setBanners(data);

          // Incrementar el índice cada vez que el componente se monta
          const newIndex = (currentIndex + 1) % data.length;
          setCurrentIndex(newIndex);
          localStorage.setItem('bannerIndex', newIndex);
        }
      } catch (error) {
        console.error('Error al obtener el banner:', error);
        setIsOffline(true); // Marca que no hay conexión o falló la consulta
      }
    };

    fetchBanners();
  }, []); // Ejecuta solo al montar el componente

  useEffect(() => {
    if (banners.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          const newIndex = (prevIndex + 1) % banners.length;
          localStorage.setItem('bannerIndex', newIndex);
          return newIndex;
        });
      }, 4000); // Cambia cada 5 segundos

      return () => clearInterval(interval); // Limpia el intervalo al desmontar
    }
  }, [banners]); // Solo ejecuta el intervalo si hay banners

  if (isOffline || banners.length === 0) {
    return (
      <div className="banner">
        <img src={menuBanner} alt="mycapp" />
      </div>
    );
  }

  // Obtener el banner actual y manejar el enlace
  const currentBanner = banners[currentIndex];
  let enlaceBanner = currentBanner?.enlace || `/comercio/${currentBanner.id}`; // Ruta alternativa si no hay enlace válido

  // Validar si el enlace es externo
  const isExternalLink = enlaceBanner.startsWith('http');

  // console.log('Banner actual:', currentBanner);
  // console.log('Enlace del banner:', enlaceBanner);
  // console.log('Es enlace externo:', isExternalLink);

  return isExternalLink ? (
    <a
      href={enlaceBanner} // `href` para enlaces externos
      style={{ textDecoration: 'none' }}
      className="banner"
      target="_blank" // Abre el enlace en una nueva pestaña
      rel="noopener noreferrer" // Para seguridad al abrir en una nueva pestaña
    >
      <div>
        <img src={`/banners/${currentBanner.imagen}`} alt="Banner" />
      </div>
    </a>
  ) : (
    <Link
      to={enlaceBanner} // `to` solo para enlaces internos
      style={{ textDecoration: 'none' }}
      className="banner"
    >
      <div>
        <img src={`/banners/${currentBanner.imagen}`} alt="Banner" />
      </div>
    </Link>
  );
}

export default CompMenuBanner;
