import React, { useEffect, useRef, useState, useContext } from 'react';
import logo from '../../assets/logo1024t.png';
import { useNavigate } from 'react-router-dom'; // Importamos useNavigate de react-router-dom
import { ComparsaContext } from '../../context/ComparsaContext'; // Importa el contexto
import SocialLinks from '../SocialLinks';


function Ficha({ items, type, rPadre, rHijo}) {
    const navigate = useNavigate();
    const [selectedFilaId, setSelectedFilaId] = useState('');
    const [selectedFilaNombre, setSelectedFilaNombre] = useState(''); // Nombre de la fila seleccionada

    // Usa el contexto para manejar el estado de la comparsa seleccionada
    const { selectedComparsaId, setSelectedComparsaId } = useContext(ComparsaContext);

    useEffect(() => {
      const storedFilaId = localStorage.getItem('selectedFilaId') || '';
      const storedFilaNombre = localStorage.getItem('selectedFilaNombre') || '';
      setSelectedFilaId(storedFilaId);
      setSelectedFilaNombre(storedFilaNombre);
    }, []);
  
    // console.log('selectedFilaId: ',selectedFilaId);
    // console.log('selectedFilaNombre: ',selectedFilaNombre);
  
    const renderItem = (item, bloque, id_padre= null) => {
      let style = {};

      // Condición para bloques "-1" (en negrita) TITULO ACTO
      if (bloque === -1) {
        style.fontWeight = 'bold';
        style.color = 'rgb(12, 110, 12)';
        style.marginBottom = '20px';
      }
  
      // Condición para bloques que empiezan con "b" y coloraciones según ID  BANDAS-PIEZAS || CARGOS DIRECCION
      if ((typeof bloque === 'string' && bloque.startsWith('b')) || (typeof bloque === 'number' && bloque === -2)) {
        if (item.id === -30) {
            style.color = 'rgb(43, 43, 223)';
            style.marginTop = '20px';
        } else if (item.id === -31) {
            style.color = 'rgb(131, 131, 255)';
            if (typeof bloque === 'string') {
              style.marginBottom = '20px';
            } else {
              style.marginBottom = '0px';
            }
        }
      }

      // Si el id o id2 es igual al selectedFilaId, pintarlo de verde   / Si pone todas y es tu comparsa
      if ( item.id === Number(selectedFilaId) || item.id2 === Number(selectedFilaId) || ( Number(selectedComparsaId) === id_padre &&  item.nombre.toLowerCase() === 'todas') ) {
        style.background = 'linear-gradient(to right, transparent 0%, rgba(240, 255, 28, 0.878) 50%, rgba(240, 255, 28, 0.878) 50%, transparent 100%)';  // Degradado de amarillo a transparente
        style.padding = '5px';  // Espacio dentro del bloque
        style.width = '98.5%';  // Asegura que ocupe todo el ancho disponible
        style.textAlign = 'center';  // Centrar el texto
        style.margin = '0px 0';  // Margen entre bloques
      }
  
      // Si el id es -20, pintarlo de rojo --CAPITANÍA xxxx--
      if (item.id === -20) {
        style.color = 'red';
      }
  
      return (
        <div key={item.nombre} style={style}>
          {item.nombre}
        </div>
      );
    };


    const renderBloque = (bloque, id_padre = null) => {

      // Verifica si el bloque es un número mayor a 0
      if (typeof bloque.bloque === 'number' && bloque.bloque > 0) {
        let bloqueStyle = {
          fontWeight: 'bold',
          fontSize: '12px', // Texto más pequeño
          color: 'green', // Color gris claro
        };
    
        return (
          <div key={`bloque-${bloque.bloque}`}>
            {/* Mostrar el nombre del bloque antes de los items */}
            <div style={bloqueStyle}>
              {`Bloque ${bloque.bloque}`}
            </div>
            {/* Mostrar los items dentro del bloque */}
            {bloque.items.map((item) => renderItem(item, bloque.bloque, id_padre))}
          </div>
        );
      }

      if (typeof bloque.bloque === 'number' && (bloque.bloque === -2 || bloque.bloque === -3)) {
        let bloqueStyle = {
          fontWeight: 'bold',
          fontSize: '12px', // Texto más pequeño
          color: 'green', // Color gris claro
        };
    
        return (
          <div key={`bloque-${bloque.bloque}-${Math.random()}`}>
            {/* Mostrar el nombre del bloque antes de los items */}
            {bloque.bloque > 0 && (
              <div style={bloqueStyle}>
                {`Bloque ${bloque.bloque}`}
              </div>
            )}
            {/* Mostrar los items dentro del bloque */}
            {bloque.items.map((item) => renderItem(item, bloque.bloque, id_padre))}
          </div>
        );
      }
    
      // Si el bloque no es un número válido o no cumple la condición, solo muestra los items
      return (
        <div key={`bloque-${Math.random()}`}>
          {bloque.items.map((item) => renderItem(item, bloque.bloque, id_padre))}
        </div>
      );
    };
  
  
    if (!items || items.length === 0) {
      return <div>No hay items para mostrar.<p>{type}</p><p>{items}</p></div>;
    }


    if (type === 'comparsa' || type === 'filas') {
        // Se reestablece estado para la selección temporal para dirigir a cargos correctamente
        localStorage.setItem('selectedIdTemporal', items._idPrincipal);
    }







  return (
    <div className="home-ficha">

      {/* PRIMER DIV: NombrePadre alineado a la derecha en un contenedor verde */}
      <div className="nombre-padre">
        <p>{items.nombrePadre}</p>
      </div>

      {/* IMAGEN PRINCIPAL: ajustada al ancho completo */}
      {items.fotoPrincipal && (
        <div className="foto-principal">
          <img
            src={`${rHijo}${items.fotoPrincipal}`}
            alt={items.nombre}
            className={items.fotoPrincipal.endsWith('.png') ? 'imagen-png' : 'imagen-normal'}
          />
        </div>
      )}


      {/* IMAGEN FLOTANTE: Icono en la esquina superior izquierda */}
      {items.icoPadre && type !== 'comercio' && (
        <div className="ico-foto-flotante">
          <img src={`${rPadre}${items.icoPadre}`} alt="Icono principal" />
        </div>
      )}

      {/* SEGUNDO DIV: Nombre principal centrado en negrita */}
      <div className="nombre-principal">
        <p>{items.nombre ? items.nombre : 'Sin Nombre'}</p>
      </div>



      {/* DIV CONTENEDOR: Todo lo que viene después con padding */}
      <div className="contenido">
        {/* BOTONES */}

        {type === 'comparsa' && (
          <div className="boton" onClick={() => navigate(`${`/comparsa/${items._idPrincipal}/cargos`}`/* , { replace: true } */)}>
            Cargos Festeros 
          </div>
        )}

        {type === 'acto' && items.idOrden && (
          <div className="boton" onClick={() => navigate(`${`/orden/${items.idOrden}`}`/* , { replace: true } */)}>
            Orden de Desfile 
          </div>
        )}

        {items.punto && (
          <div
            className="boton"
            onClick={() => navigate('/map', { state: { punto: items.punto, recorrido: items.recorrido } })}
          >
            {items.recorrido ? 'Recorrido' : 'Ubicación'}
          </div>
        )}



        {/* AÑO DE FUNDACIÓN */}
        {(type === 'fila' || type === 'comparsa') && (
          <div className="anyo">
            <strong style={{color: 'green'}}>Año de Fundación:</strong>
            <div>{items.anyo ? items.anyo : 'Desconocido'}</div>
          </div>
        )}



        {/* FECHA Y HORA INICIO */}
        {(type === 'acto' || type === 'orden') && items.inicio && (
          <div>
            <div className="inicio">
              {items.inicio}
            </div>
            
            {/* Comprobamos si 'inicio' termina en un número */}
            {/\d$/.test(items.inicio) ? (
              <div className="duracion">
                <p><strong>Horario orientativo,</strong></p>
                <div><strong>podría variar.</strong></div>
                {items.duracion > 0 && <p><b>Duración aprox.: </b>{items.duracion} min.</p>}
              </div>
            ) : (
              <div className="duracion">
                <p><strong>Horario disponible</strong></p>
                <div><strong>próximamente.</strong></div>
              </div>
            )}
          </div>
        )}



        {/* IMPORTANTE */}
        {/* IMPORTANTE AC */}
        {items.importanteAC && (
          <div className="importante">
            {items.importanteAC.trim().startsWith('[') ? (
              // Si importanteAC empieza por "[{", usa renderBloque
              <>
              <div>FILAS OBLIGADAS:</div>
              {JSON.parse(items.importanteAC).map((bloque) => renderBloque(bloque, items.id_padre))}
              </>
            ) : (
              // Si no empieza por "[{", usa dangerouslySetInnerHTML
              <p dangerouslySetInnerHTML={{ __html: items.importanteAC.replace(/\r?\n/g, '<br />') }}></p>
            )}
          </div>
        )}

        {/* IMPORTANTE A */}
        {items.importanteA && (
          <div className="importante">
            {items.importanteA.trim().startsWith('[') ? (
              // Si importanteA empieza por "[{", usa renderBloque
              JSON.parse(items.importanteA).map((bloque) => renderBloque(bloque))
            ) : (
              // Si no empieza por "[{", usa dangerouslySetInnerHTML
              <p dangerouslySetInnerHTML={{ __html: items.importanteA.replace(/\r?\n/g, '<br />') }}></p>
            )}
          </div>
        )}

        {/* IMPORTANTE */}
        {items.importante && (
          <div className="importante">
            {items.importante.trim().startsWith('[') ? (
              // Si importanteA empieza por "[{", usa renderBloque
              JSON.parse(items.importante).map((bloque) => renderBloque(bloque))
            ) : (
              // Si no empieza por "[{", usa dangerouslySetInnerHTML
              <p dangerouslySetInnerHTML={{ __html: items.importante.replace(/\r?\n/g, '<br />') }}></p>
            )}
          </div>
        )}


        {/* DESCRIPCIÓN */}
{/*         {items.descripcion && (
          <div className="descripcion">
            <p>{items.descripcion}</p>
          </div>
        )} */}
        {type === 'orden' ? (
          <div className="descripcion" style={{ textAlign: 'center' }}>
            {items.descripcion.map((bloque) => renderBloque(bloque))}
          </div>
        ) : ( 
          // Si no es 'orden', muestra la descripción
            <div className="descripcion">
              <p dangerouslySetInnerHTML={{ __html: items.descripcion.replace(/\r?\n/g, '<br />') }}></p>
            </div>
          )} 



        {/* DIRECCIÓN Y CONTACTO*/}
        <SocialLinks text={items.contacto} />

        {(items.direccion) && (
          <div className="direccion-contacto">
            <strong style={{color: 'green'}}>Encuentranos en:</strong>
          </div>
        )}

        {(items.direccion || items.contacto) && (
          <div className="direccion-contacto">
            <p dangerouslySetInnerHTML={{ __html: items.direccion.replace(/\r?\n/g, '<br />') }}></p>
          </div>
        )}


        {/* LOGO APP */}
        {/* EMAIL APP */}
        <div className="correo">
        <img style={{ maxWidth: "60px"}} src={logo} alt="Logo" />
          <div>Para ampliar, corregir, modificar</div>
          <div>o eliminar algún dato:</div>
          <a href={`mailto:${process.env.REACT_APP_EMAIL}`}>
            {process.env.REACT_APP_EMAIL}
          </a>
        </div>



      </div>































{/* <br/><br/><br/>

      <div className="grid-container">
          <div>
            <p>{items._idPrincipal}</p>
            <p>{items.id_padre}</p>
            <p>{items.icoPadre}</p>
            <p>{items.nombrePadre}</p>
            <p>{items.fotoPrincipal}</p>
            <p>{items.nombre}</p>
            <p>{items.inicio}</p>
            <p>{items.duracion}</p>
            <p>{items.importanteAC}</p>
            <p>{items.importanteA}</p> */}
                    {/* Condición basada en el valor de 'type' */}
{/*            {type === 'orden' ? (
            <div className="descripcion">
              {items.descripcion.map((bloque) => (
                <div key={bloque.bloque}>
                  {bloque.items.map((item) => renderItem(item, bloque.bloque))}
                </div>
              ))}
            </div>
          ) : (  */}
       {/*      // Si no es 'orden', muestra la descripción
            <p>{items.descripcion}</p>
           )} 
            <p>{items.direccion}</p>
            <p>{items.contacto}</p>
            <p>{items.idOrden}</p>
            <p>{items.coordenadas}</p>
            <p>{items.puntoA}</p>
            <p>{items.recorrido}</p>
          </div>
      </div> */}
    </div>
  );
}

export default Ficha;

