// Función para SocialLinks
  export const parseTextToLinks = (text) => {
    const items = text.split(';').map((item) => item.trim());
    const linkArray = [];
  
    items.forEach((item) => {
        const [type, value] = item.split(',').map((str) => str.trim());
        if (type && value) {
          linkArray.push({ type, value });
        }
    });
  
    return linkArray;
};
 

// Función para detectar y convertir números de teléfono, correos electrónicos o URLs en enlaces
export const createLinkIfDetected = (text) => {
  // Primero, reemplazar los saltos de línea por <br />
  const formattedText = text.replace(/\r?\n/g, ' <br />');

  // Expresión regular para detectar un número de teléfono
  const phoneRegex = /(\+\d{1}\s\d{3}\s\d{3}\s\d{3})|(\+\d{1}\s\d{2}\s\d{3}\s\d{2}\s\d{2})|(\+\d{2}\s\d{3}\s\d{3}\s\d{3})|(\+\d{2}\s\d{2}\s\d{3}\s\d{2}\s\d{2})|(\+\d{3}\s\d{3}\s\d{3}\s\d{3})|(\+\d{3}\s\d{2}\s\d{3}\s\d{2}\s\d{2})|(\d{2}\s\d{3}\s\d{2}\s\d{2})|(\d{3}\s\d{3}\s\d{3})/g;

  
  // Expresión regular para detectar un correo electrónico
  const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/g;

  // Expresión regular para detectar URLs que comienzan con http, https, o www
  const urlRegex = /((https?:\/\/|www\.)[a-zA-Z0-9._-]+\.[a-zA-Z]{2,}(\/[^\s]*)?)/g;

  // Si detecta un número de teléfono en los formatos permitidos
  const withPhoneLinks = formattedText.replace(phoneRegex, (phone) => `<a href="tel:${phone.replace(/\s+/g, '')}">${phone}</a>`);
  
  // Si detecta un correo electrónico
  const withEmailLinks = withPhoneLinks.replace(emailRegex, (email) => `<a href="mailto:${email}">${email}</a>`);
  
  // Si detecta una URL
  const withLinks = withEmailLinks.replace(urlRegex, (url) => {
    const urlWithProtocol = url.startsWith('http') ? url : `https://${url}`;
    return `<a href="${urlWithProtocol}" target="_blank">${url}</a>`;
  });

  return withLinks;
};