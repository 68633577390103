// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';

// function CompMenuMiniBanner() {
//   const [bannerMini, setBanner] = useState(null);

//   useEffect(() => {
//     const fetchBanners = async () => {
//       try {
//         const response = await fetch(`${process.env.REACT_APP_API_URL}/banner-mini`);
//         if (!response.ok) {
//           throw new Error('Error al obtener los banners-mini');
//         }
//         const bannerMini = await response.json();

//         // Obtener el índice del último banner mostrado desde localStorage
//         let currentIndex = parseInt(localStorage.getItem('bannerMiniIndex'), 10) || 0;

//         // Incrementar el índice para mostrar el siguiente banner
//         currentIndex = (currentIndex + 1) % bannerMini.length;

//         // Guardar el nuevo índice en localStorage
//         localStorage.setItem('bannerMiniIndex', currentIndex);

//         // Establecer el banner actual
//         let currentBanner = bannerMini[currentIndex];

//         // Verificar si el enlace ya existe y es válido
//         const enlaceExiste = currentBanner.enlace_banner && currentBanner.enlace_banner.trim() !== "";

//         // Si el enlace está vacío o nulo, asignar una ruta alternativa
//         if (!enlaceExiste) {
//           currentBanner.enlace_banner = `/comercio/${currentBanner.id}`; // Ruta alternativa
//         }

//         setBanner(currentBanner);
//       } catch (error) {
//         console.error('Error al obtener el banner:', error);
//       }
//     };

//     fetchBanners();
//   }, []); // El array vacío hace que useEffect se ejecute solo una vez al montar el componente

//   if (!bannerMini) {
//     return null; // No renderiza nada si no hay banner
//   }


//   return (
//     <div className="minibanner">
//       <div className="minibanner-background" style={{ backgroundImage: `url(/comercios/${bannerMini.imagen})` }}></div>
//       <Link to={bannerMini.enlace_banner} style={{ textDecoration: 'none' }} target={bannerMini.enlace_banner.startsWith('http') ? "_blank" : "_self"} rel={bannerMini.enlace_banner.startsWith('http') ? "noopener noreferrer" : undefined}>
//         <div className="minibanner-principal">
//           <img src={`/comercios/${bannerMini.imagen}`} alt="Banner" />
//         </div>
//       </Link>
//     </div>

//   );
// }

// export default CompMenuMiniBanner;



import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function CompMenuMiniBanner() {
  const [banners, setBanners] = useState([]); // Todos los banners
  const [currentIndex, setCurrentIndex] = useState(
    () => parseInt(localStorage.getItem('bannerMiniIndex'), 10) || 0
  );

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/banner-mini`);
        if (!response.ok) {
          throw new Error('Error al obtener los banners-mini');
        }
        const data = await response.json();
        if (data.length > 0) {
          setBanners(data);

          // Incrementar el índice cada vez que el componente se monta
          const newIndex = (currentIndex + 1) % data.length;
          setCurrentIndex(newIndex);
          localStorage.setItem('bannerMiniIndex', newIndex);
        }
      } catch (error) {
        console.error('Error al obtener los banners:', error);
      }
    };

    fetchBanners();
  }, []); // Ejecuta solo al montar el componente

  useEffect(() => {
    if (banners.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          const newIndex = (prevIndex + 1) % banners.length;
          localStorage.setItem('bannerMiniIndex', newIndex);
          return newIndex;
        });
      }, 4000); // Cambia cada 5 segundos

      return () => clearInterval(interval); // Limpia el intervalo al desmontar
    }
  }, [banners]); // Solo ejecuta el intervalo si hay banners

  if (banners.length === 0) {
    return null; // No muestra nada si no hay banners
  }

  // Obtener el banner actual y manejar el enlace
  const currentBanner = banners[currentIndex];
  let enlaceBanner = `/comercio/${currentBanner.id}`; // Ruta alternativa si no hay enlace válido

  // Validar si el enlace es externo
  // const isExternalLink = enlaceBanner.startsWith('http');
  const isExternalLink = false;

  return (
    <div className="minibanner">
      <div
        className="minibanner-background"
        style={{ backgroundImage: `url(/comercios/${currentBanner.imagen})` }}
      ></div>
      <Link
        to={isExternalLink ? undefined : enlaceBanner} // `to` solo para enlaces internos
        href={isExternalLink ? enlaceBanner : undefined} // `href` para enlaces externos
        style={{ textDecoration: 'none' }}
        target={isExternalLink ? "_blank" : "_self"} // Si el enlace es externo, se abre en una pestaña nueva
        rel={isExternalLink ? "noopener noreferrer" : undefined} // Para seguridad al abrir en una nueva pestaña
      >
        <div className="minibanner-principal">
          <img src={`/comercios/${currentBanner.imagen}`} alt="Banner" />
        </div>
      </Link>
    </div>
  );
}

export default CompMenuMiniBanner;
