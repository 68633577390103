import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'; // Importamos Link de react-router-dom

function GridM00b({ items, ruta, src, titulo, posicionTemp }) {

  const itemRefs = useRef([]);
  const [cargando, setCargando] = useState(true);

  useEffect(() => {
    if (items.length > 0) {
      setCargando(false); // Deja de mostrar "Cargando..." cuando los items están disponibles
    }
  }, [items]);

  useEffect(() => {
    console.log('Ejecutando useEffect con posicionTemp:', posicionTemp);
    
  
    if (posicionTemp !== null && typeof posicionTemp === 'number') {
      console.log('Verificando si la referencia al elemento está asignada:', itemRefs.current[posicionTemp]);
  
      const elemento = itemRefs.current[posicionTemp];
      if (elemento) {
        setTimeout(() => {
          console.log('Haciendo scroll a:', elemento);
          elemento.scrollIntoView({ behavior: 'smooth', block: 'center' });
          localStorage.setItem('posicionTemp', 0);
        }, 100); // Aumenta el tiempo de espera si es necesario
      } else {
        console.log('Referencia aún no asignada, esperando...');
        setTimeout(() => {
          const elementoReintento = itemRefs.current[posicionTemp];
          if (elementoReintento) {
            elementoReintento.scrollIntoView({ behavior: 'smooth', block: 'center' });
          } else {
            console.log('El elemento aún no está disponible después del reintento');
          }
        }, 500); // Reintento con más tiempo de espera
      }
    } else {
      console.log('posicionTemp es null o no es un número');
    }
  }, [posicionTemp]);


  return (
    <div className="grid-container-a">
      <div className="grid-title"><b>{titulo}</b></div>
      <div className="inner-scroll-container">
        {cargando ? (
          <div className="title-vent">
            <b>Cargando...</b>
          </div>
        ) : (
          items.map((item, index) => {
            let backgroundColor = '';
            if (Number(posicionTemp) === index && Number(posicionTemp) !== 0) {
              backgroundColor = 'rgba(144, 255, 134, 0.401)';
              console.log(Number(posicionTemp));  
              console.log(index);
              console.log(Number(posicionTemp) === index);
            }

            return (
            <Link 
              /*  Si empieza po "O" serán órdenes, si no cargos (y pasamos nombre (año)) */
              to={
                titulo?.startsWith("O")
                  ? `${ruta}${item.id}`
                  : `/comparsa/${item.idPadre}/cargos/${item.nombre}`
              }
              key={index} 
              className="grid-item-t"
              ref={(el) => (itemRefs.current[index] = el)} // Asignar la referencia
              style={{ backgroundColor: backgroundColor }}
              onClick={() => {
                localStorage.setItem('posicionTemp', index); // Guardar el índice en localStorage
              }}
            >
              <img src={`${src}${item.imagen}`} alt={item.nombre} className="grid-image-b" />
              <b className="grid-text-b">{item.nombre}</b>
            </Link>
          )})
        )}
        <div style={{ height: '200px' }}></div>
      </div>
    </div>
  );
}

export default GridM00b;