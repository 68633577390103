import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import menu1_1 from '../../assets/menus/menu1_1.jpg'; // Concursos de Fotografía
// import menu2 from '../../assets/menus/menu1_2.jpg'; // Capitanes
// import menu3 from '../../assets/menus/menu1_3.jpg'; // Navidades
// import menu3b from '../../assets/menus/menu1_10.jpg'; // 75 Moros Nuevos
// import menu4 from '../../assets/menus/menu1_4.jpg'; // Directores y Pregoneros
// import menu5 from '../../assets/menus/menu1_5.jpg'; // Conciertos
// import menu6 from '../../assets/menus/menu1_6.jpg'; // Banderas
// import menu7 from '../../assets/menus/menu1_7.jpg'; // Entraetas / Desfile Infantil
// import menu8 from '../../assets/menus/menu1_8.jpg'; // Moros
// import menu9 from '../../assets/menus/menu1_9.jpg'; // Procesión Cristo
// import menu10 from '../../assets/menus/menu1_10.jpg'; // 75 Moros Nuevos

function CompMenuActos() {
  const [menuActual, setMenuActual] = useState(menu1_1);

  // Función para calcular el día del año
  const calcularDiaDelAño = (mes, dia) => {
    const fecha = new Date(new Date().getFullYear(), mes, dia);
    const inicioAño = new Date(fecha.getFullYear(), 0, 0);
    const diferencia = fecha - inicioAño;
    return Math.floor(diferencia / (1000 * 60 * 60 * 24));
  };

  useEffect(() => {
    const fechaActual = new Date();
    const diaActualDelAño = calcularDiaDelAño(fechaActual.getMonth(), fechaActual.getDate());
    // const diaActualDelAño = calcularDiaDelAño(11,27);

    // Fechas clave con imágenes asignadas
    const periodos = [
      // { diaInicio: calcularDiaDelAño(0, 7), imagenes: [menu4] }, // 7 de enero: Directores y Pregoneros
      // { diaInicio: calcularDiaDelAño(2, 1), imagenes: [menu5] }, // Marzo: Conciertos
      // { diaInicio: calcularDiaDelAño(3, 7), imagenes: [menu6] }, // 7 de abril: Banderas
      // { diaInicio: calcularDiaDelAño(3, 28), imagenes: [menu7] }, // 28 de abril: Entraetas / Desfile Infantil
      // { diaInicio: calcularDiaDelAño(4, 12), imagenes: [menu8] }, // 12 de mayo: Moros
      // { diaInicio: calcularDiaDelAño(4, 22), imagenes: [menu9] }, // 22 de mayo: Procesión Cristo
      // { diaInicio: calcularDiaDelAño(5, 16), imagenes: [menu10] }, // 16 de junio: 75 Moros Nuevos
      // { diaInicio: calcularDiaDelAño(8, 1), imagenes: [menu1] }, // Septiembre: Concursos de Fotografía
      // { diaInicio: calcularDiaDelAño(9, 15), imagenes: [menu2] }, // Mediados de octubre: Capitanes
      // { diaInicio: calcularDiaDelAño(11, 1), imagenes: [menu3, menu3b, menu2, menu1, menu4, menu5, menu6] }, // Diciembre: Navidades
      { diaInicio: calcularDiaDelAño(8, 1), imagenes: [menu1_1] }, // Septiembre: Concursos de Fotografía
    ];

    // Ordenar periodos por día de inicio
    periodos.sort((a, b) => a.diaInicio - b.diaInicio);

    periodos.forEach(periodo => 
      console.log(`Día inicio: ${periodo.diaInicio}, Imágenes: ${periodo.imagenes}`));

    // Determinar el período actual
    let imagenesSeleccionadas = [];
    for (let i = 0; i < periodos.length; i++) {
      const diaInicioActual = periodos[i].diaInicio;
      const diaInicioSiguiente = i < periodos.length - 1 ? periodos[i + 1].diaInicio : calcularDiaDelAño(0, 7);
    
      console.log(`Comprobando período: ${diaInicioActual} - ${diaInicioSiguiente}, Día actual: ${diaActualDelAño}`);
    
      const esPeriodoActual =
      (diaInicioActual <= diaActualDelAño && diaActualDelAño < diaInicioSiguiente) || 
      (diaInicioActual > diaInicioSiguiente && 
       (diaActualDelAño >= diaInicioActual || diaActualDelAño < diaInicioSiguiente));
    
      if (esPeriodoActual) {
        imagenesSeleccionadas = periodos[i].imagenes;
        console.log(`Periodo seleccionado: ${diaInicioActual}, Imágenes: ${imagenesSeleccionadas}`);
        break;
      }
    }



    // Seleccionar aleatoriamente una imagen del período actual
    if (imagenesSeleccionadas.length > 0) {
      const indiceAleatorio = Math.floor(Math.random() * imagenesSeleccionadas.length);
      setMenuActual(imagenesSeleccionadas[indiceAleatorio]);
    }
  }, []);

  return (
    <Link to="/actos" style={{ textDecoration: 'none' }} className="actos">
      <div>
        <img src={menuActual} alt="Actos" />
        <div className="element-text">Actos y Horarios</div>
      </div>
    </Link>
  );
}

export default CompMenuActos;
