import React from 'react';
import ListaOrdenes from '../components/vistas/ListaOrdenes.js';

function PageListaOrdenes() {
  return (
    <div>  
      <ListaOrdenes />
    </div>
  );
}

export default PageListaOrdenes;
