import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Importamos useNavigate
import logo from '../../assets/logo1024t.png';
import CompCookiesModal from './CompCookiesModal';
import { ComparsaContext } from '../../context/ComparsaContext';
import { CookiesContext } from '../../context/CookiesContext';

function CompMenuHeader() {
  const { selectedComparsaId } = useContext(ComparsaContext);
  const { cookiesAceptadas } = useContext(CookiesContext);
  const navigate = useNavigate(); // Inicializamos useNavigate
  const location = useLocation(); // Obtenemos la ubicación actual

  // Función para redirigir al home y borrar el historial
  const handleLogoClick = () => {
    navigate('/', { replace: true }); // Redirige y reemplaza el historial
  };
  const handleAvisoClick = () => {
    navigate(`/avisos/${selectedComparsaId}`); // Redirige y reemplaza el historial
  };

  // Función para redirigir al perfil
  const handleProfileClick = () => {
    navigate('/perfil'); // Redirige a la página de perfil
  };

  // Comprobar si la ruta actual es "/perfil"
  const isOnProfilePage = location.pathname === '/perfil';

  const avisoInicial = localStorage.getItem('avisoInicial');
  const [avisos, setAvisos] = useState([]);
  const urlAviso = `${process.env.REACT_APP_API_URL}/avisos-actos/${selectedComparsaId}`;
  useEffect(() => {
    // Realizar la consulta a la API
    fetch(urlAviso)
      .then(response => response.json())
      .then(data => {
        // console.log('Datos recibidos:', data);
        setAvisos(data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [urlAviso]); // Reejecuta el efecto si cambian url, type, o posicionActo

  // Recuperar los avisos leídos desde localStorage
  const avisosLeidos = JSON.parse(localStorage.getItem('avisosLeidos')) || [];

  // Lógica para mostrar el ícono de avisos
  const shouldShowAvisos = avisos.some(aviso => !avisosLeidos.includes(aviso.id));

  return (
    <div className="nav">
      <div className="nav-content">

        {/* Logo para redirigir al inicio */}
        <div className="nav-item left">
          <div onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
            <img src={logo} alt="Vuelta al menú" />
          </div>
        </div>

        {/* Ícono de avisos */}
        {shouldShowAvisos && (
          <div className="nav-item-aviso left" style={{ margin: '10px 16px 15px 34px' }}>
            <div onClick={handleAvisoClick} style={{ cursor: 'pointer' }}>
              <img src={'/iconos/ic_avi.png'} alt="Avisos" />
            </div>
          </div>
        )}

        {/* Redirigir al perfil (no mostrar si estamos en /perfil) */}
        {!isOnProfilePage && (
          <div className="nav-item">
            <button
              className="profile-button"
              onClick={handleProfileClick}
              style={{ cursor: 'pointer' }}
            >
              <span className={`up-arrow ${isOnProfilePage ? 'open' : ''}`}></span>
              <img src={`/comparsas/c_${selectedComparsaId}.png`} alt="Perfil" />
            </button>
          </div>
        )}

        {/* Mostrar el modal de cookies si no se han aceptado */}
        {!cookiesAceptadas && <CompCookiesModal />}
      </div>
    </div>
  );
}

export default CompMenuHeader;
