import React, { useState, useEffect } from 'react';

function CompMenuMinibannerPropio({ id }) {
  const [bannerMini, setBanner] = useState(null);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/banner-mini`);
        if (!response.ok) {
          throw new Error('Error al obtener los banners-mini');
        }
        const bannersMinis = await response.json();

        // Comparar el id como número (asegúrate de que el id en la API y el prop tengan el mismo tipo)
        const currentBanner = bannersMinis.find(banner => banner.id === Number(id) || banner.id === id);

        if (currentBanner) {
          setBanner(currentBanner);
        } else {
          console.warn(`No se encontró un banner con id ${id}`);
        }
      } catch (error) {
        console.error('Error al obtener el banner:', error);
      }
    };

    fetchBanners();
  }, [id]); // Agregar 'id' como dependencia

  if (!bannerMini) {
    return null; // No renderiza nada si no hay banner
  }

  return (
    <div className="minibanner">
      <div
        className="minibanner-background"
        style={{ backgroundImage: `url(/comercios/${bannerMini.imagen})` }}
      ></div>
      <div className="minibanner-principal">
        <img src={`/comercios/${bannerMini.imagen}`} alt="BannerMini" />
      </div>
    </div>
  );
}

export default CompMenuMinibannerPropio;
