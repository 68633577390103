import React, { useEffect, useState } from 'react';
import Minibanner from '../components/menu/CompMenuMinibanner';
import Relleno from '../components/menu/CompMenuMBT';
import Grid from '../components/GridPrepare'; // Asegúrate de ajustar la ruta si es necesario
import { Link } from 'react-router-dom';


function PageListaPerfil() {

  // Usar useEffect para desplazar al principio cuando se monta el componente
  useEffect(() => {
    window.scrollTo(0, 0); // Colocar el scroll en el inicio de la página
  }, []);

  const [comparsa, setComparsa] = useState(localStorage.getItem('selectedComparsa') || '');
  const [filaId, setFilaId] = useState(localStorage.getItem('selectedFilaId') || '');
  let [filaNombre, setFilaNombre] = useState(localStorage.getItem('selectedFilaNombre') || '');

  if (filaId < 1) {
    filaNombre = '';
  }

  if (!comparsa) {
    localStorage.setItem('selectedComparsa', 0);
  }
  
console.log('selectedFilaId: ',filaId);

  return (
    <div>
      <Minibanner />
      <Relleno />
      <div className="home2">
        <div className="grid-container-t">
            <div className="grid-title">
                <b>Mi Perfil</b>
            </div>      
        </div>
        {/* Comparsa y Fila */}
        <div className="perfil-content">
          {comparsa === '0' ? (
            <>
              {/* Mostrar Actos Generales si la comparsa es '0' */}
              <Link to="/ajustes" className="perfil-item">
                <strong>Mi Comparsa:</strong> Actos Generales
                <div className="perfil-image-container">
                  <img
                    src={`/comparsas/c_${comparsa}.png`}
                    alt="Mi Comparsa"
                    className="perfil-image"
                  />
                </div>
                <span className="right-arrow"></span>
              </Link>
              <div className="perfil-item">
                <strong>Mi Fila:</strong> Sin Comparsa seleccionada
              </div>
            </>
          ) : (
            <>
              {/* Mostrar información normal si hay una comparsa seleccionada */}
              <Link to="/ajustes" className="perfil-item">
                <span>Mi Comparsa:</span>
                <div className="perfil-image-container">
                  <img
                    src={`/comparsas/c_${comparsa}.png`}
                    alt="Mi Comparsa"
                    className="perfil-image"
                  />
                </div>
                <span className="right-arrow"></span>
              </Link>
              <Link to="/ajustes" className="perfil-item">
                <span>Mi Fila:</span>
                <div className="nombre-fila">
                  <b>{filaNombre || 'No seleccionada'}</b>
                </div>
                <span className="right-arrow"></span>
              </Link>
            </>
          )}
        </div>

        {/* Actos Obligatorios */}
        {filaNombre && (
          <div className="perfil-item">
            <div className="grup-star">
              <img src={`/actos/star.png`} alt="star" className="star" />
              <div>
                <strong>Asignado a mi Fila:</strong>
              </div>
            </div>
            <Grid type="actosFila" id={filaId} id2={comparsa} />
          </div>
        )}
        <br />
        {/* Avisos importantes */}
        <div className="perfil-item">
          <div className="grup-star">
            <img src={`/iconos/ic_avi.png`} alt="Avisos" className="star" />
            <div>
              <strong>Avisos importantes:</strong>
            </div>
          </div>
          <Grid type="avisosPerfil" id={comparsa} />
        </div>
      </div>
    </div>
  );
}

export default PageListaPerfil;
