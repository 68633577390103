import React, { useState, useEffect } from 'react';
import {createLinkIfDetected} from '../utils/utils';

function GridAVISOSperfil({ avisos }) {

  // Estado local para manejar avisoInicial
  const [avisoInicial, setAvisoInicial] = useState(() => {
    const storedValue = localStorage.getItem('avisoInicial');
    return storedValue === "true" || storedValue === null; // Mostrar por defecto si es null o "true"
  });

  // Estado local para manejar avisos leídos
  const [avisosLeidos, setAvisosLeidos] = useState(() => {
    const storedLeidos = localStorage.getItem('avisosLeidos');
    return storedLeidos ? JSON.parse(storedLeidos) : []; // Recuperar los leídos o iniciar vacío
  });

  // Actualizar localStorage cada vez que cambia `avisoInicial`
  useEffect(() => {
    localStorage.setItem('avisoInicial', avisoInicial.toString());
  }, [avisoInicial]);

  // Actualizar localStorage cada vez que cambian `avisosLeidos`
  useEffect(() => {
    localStorage.setItem('avisosLeidos', JSON.stringify(avisosLeidos));
  }, [avisosLeidos]);

  // Lógica para manejar el marcado de todos los avisos como leídos
  const handleMarcarLeidos = () => {
    // Actualizar localStorage directamente
    localStorage.setItem('avisoInicial', "false");
    setAvisoInicial(false);
    const idsLeidos = avisos.map(aviso => aviso.id); // Obtener todos los IDs de los avisos
    setAvisosLeidos(prevLeidos => Array.from(new Set([...prevLeidos, ...idsLeidos]))); // Evitar duplicados
  };

  // Verificar si un aviso está leído
  const estaLeido = (id) => avisosLeidos.includes(id);

  return (
    <div>
      {/* Botón para marcar todos los avisos como leídos */}
      {avisos.some(aviso => !estaLeido(aviso.id)) && (
        <button
          className="boton"
          onClick={handleMarcarLeidos}
          style={{
            backgroundColor: 'white',
            color: 'green',
            display: 'flex', // Alinea los elementos en fila
            alignItems: 'center', // Centra verticalmente
            justifyContent: 'center', // Centra horizontalmente
            padding: '5px 10px 11px 10px', // Control explícito del padding
            border: '1px solid green', // Ajustar estilo del borde
            borderRadius: '4px', // Bordes redondeados si es necesario
            cursor: 'pointer'
          }}
        >
          <div>
            Marcar todos como leídos
            <img 
              src={`/iconos/ic_che.png`}
              alt="Leído"
              style= {{
                width: "20px",
                height: "20px",
                marginLeft: "5px", // Margen a la izquierda
                transform: "translateY(8px)" // Flota 5px hacia abajo
              }}
            />
          </div>
        </button>
      )}
      {/* Lista de avisos */}
      {avisos.map((aviso, index) => (
        <div
          className="aviso-actos-int"
          key={index}
          style={{
            ...(estaLeido(aviso.id) && {
              backgroundColor: 'white', // Fondo blanco si está leído
              color: 'green', // Texto verde si está leído
              border: '1px solid green', // Borde verde si está leído
            }),
          }}
        >
          {/* Imagen a la izquierda */}
          <img
            src={`/comparsas/c_${aviso.idPadre}.png`}
            alt={`Comparsa ${aviso.nombrePadre}`}
            className="aviso-icon"
          />
          {/* Mostrar icono leído o aviso */}
          <div className="icono-leido">
            {estaLeido(aviso.id) ? (
              <img
                src={`/iconos/ic_che.png`}
                alt="Leído"
                className="aviso-icon"
              />
            ) : (
              <div className="nav-item-aviso left">
                <img src={'/iconos/ic_avi.png'} alt="Avisos" />
              </div>
            )}
          </div>
          <p>
            {(aviso.idPadre !== 0 || aviso.id === 1) ? "" : "Aviso para TODAS las Comparsas"}
          </p>
          <p><b>{aviso.nombre}</b></p>
          <p
            dangerouslySetInnerHTML={{
              __html: createLinkIfDetected(aviso.descripcion.replace(/\r?\n/g, '<br />')),
            }}
          ></p>
        </div>


      ))}
    </div>
  );
}

export default GridAVISOSperfil;
